import * as actionTypes from '../../constants/actionTypes';
import * as api from '../../api/user';
import { setFlash } from '../flash';

export const loadUser = () => (dispatch) => {
  return api.loadUser().then(
    response => {
      dispatch({
        type: actionTypes.LOAD_USER_SUCCESS,
        response
      })
    }
  )
}

export const saveUser = body => (dispatch) => {
  return api.saveUser(body).then(
    response => {
      dispatch({
        type: actionTypes.LOAD_USER_SUCCESS,
        response
      })
      setFlash('Settings Saved!')(dispatch)
    }
  )
}