import React, { Component } from 'react';

class Form extends Component {

  constructor() {
    super()
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return(
      <form onSubmit={this.handleSubmit} className={this.props.className}>
        {this.props.children}
      </form>
    )
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    const json = this.toJSON(data);
    this.sendData(json);
  }

  toJSON(data) {
    const object = {};

    data.forEach(function(value, key) {
      object[key] = value;
    });

    return object;
  }

  sendData(json) {
    this.props.action(json)
  }
}

export default Form;