import * as localStorage from '../library/localStorage';
import * as helpers from '../constants/helpers';

export default {
  recipes: {},

  recipe: {
    url: '',
    title: '',
    instructions: '',
    ingredients: [],
    tags: []
  },

  currentUser: localStorage.getItem('user') || helpers.INITIAL_USER_STATE,

  nav: false,

  search: [],
  flash: null,
  user: {
    email: '',
    meals: 5,
  },

  items: [],

  error: null,

  reoccuring: [],
  pantry: [],
}