import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as constants from '../../constants/helpers';

const List = styled.ul`
  margin-top: 25px;
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 0 10px 10px 0;
  border: 1px solid #ededed;
  padding: 0.2em 0.5em;
  border-radius: 2px;

  ::before {
    content: '';
    background-color: ${props => props.tagColor || constants.DEFAULT_TAG_COLOR};
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
  }

  :hover {
    border-color: #2e2e2e;
  }
`;

const TagList = ({ tags }) => {
  return(
    <List>
    {tags.map((tag, index) =>
      <Link to={'/tag/' + tag.slug} key={index}>
        <ListItem tagColor={tag.color}>
          {tag.name}
        </ListItem>
      </Link>
    )}
  </List>
  )
}

export default TagList