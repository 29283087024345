import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createRecipe } from '../../actions/recipes'

const Title = styled.h2`
  font-family: 'Crimson Text', serif;
  font-size: 42px;
  margin: 25px 0;
`

const ToSubmit = styled.h3`
  margin-bottom: 25px;
  font-size: 18px;
`

const Error = styled.div`
  color: #ef9970;
  margin-bottom: 10px;
`

const Form = styled.form`
  margin-bottom: 50px;
`

const formStyles = css`
  appearance: none;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  outline: none;
  display: inline-block;
`

const submitWidth = '157px'
const mediaWidth = '650px'
const borderRadius = '5px'

const Url = styled.input`
  ${formStyles}
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  padding: 15px;
  width: calc(100% - ${submitWidth});
  border-radius: ${borderRadius} 0 0 ${borderRadius};

  @media (max-width: ${mediaWidth}) {
    width: 100%;
    border-radius: ${borderRadius};
    display: block;
    border-width: 1px;
    margin-bottom: 15px;
  }
`
const buttonStyles = css`
  border: none;
  padding: 16px;
  background: #242424;
  color: #ffffff;
  cursor: pointer;
`

const Button = styled.button`
  ${formStyles}
  ${buttonStyles}
`

const Submit = styled.input`
  ${formStyles}
  ${buttonStyles}
  width: ${submitWidth};
  border-radius: 0 5px 5px 0;

  @media (max-width: ${mediaWidth}) {
    border-radius: ${borderRadius};
  }
`

const mobileWidth = '500px'

const BookmarkletContainer = styled.div`
  font-size: 14px;

`

const BookmarkletTitle = styled.h3`
  margin-bottom: 25px;
`

const BookmarkletText = styled.div`
  line-height: 28px;

  @media (max-width: ${mobileWidth}) {
    display: none;
  }
`

const Bookmarklet = styled.a`
  text-decoration: none;
  border: 1px solid #242424;
  padding: 7px 14px;
  border-radius: 3px;
`

const BookmarkletTextField = styled.textarea`
  resize: none;
  padding: 5px;
  width: 100%;
  height: 150px;
  display: none;

  @media (max-width: ${mobileWidth}) {
    display: block;
  }
`

const bookmarklet = `
  javascript:(function() {
    var js = document.createElement('script');
    js.setAttribute('src', '${process.env.REACT_APP_BOOKMARKLET_URL}');
    document.body.appendChild(js);
  })();
`

const Save = ({ error, ...rest }) => <>
  <Title>Add Recipe to Cookmatic</Title>
  {error &&
    <Error>{error}</Error>
  }
  <Content {...rest} />
</>

const Content = ({ location, createRecipe }) =>  {
  const { url, title } = queryString.parse(location.search)

  if (url) {
    return <>
      <ToSubmit>
        Add{' '}
        <a href={url}>{title}</a>
        {' '}to Cookmatic?
      </ToSubmit>
      <Button onClick={() => createRecipe({ url })}>Save to Cookmatic</Button>
    </>
  } else {
    return <Fields createRecipe={createRecipe} />
  }
}

const Fields = ({ createRecipe }) => {
  const [url, setUrl] = useState('')

  return <>
    <Form onSubmit={(e) => {
      e.preventDefault()
      createRecipe({ url })
    }}>
      <Url
        type='url'
        onChange={(e) => setUrl(e.target.value)}
        placeholder='https://cooking.com/recipes/recipe-url'
        autoFocus={true}
        required={true}
      />
      <Submit type='submit' value='Save Recipe' />
    </Form>
    <BookmarkletContainer>
      <BookmarkletTitle>Make saving recipes easier with our bookmarklet:</BookmarkletTitle>
      <BookmarkletText>
        <Bookmarklet href='/bookmarklet' ref={node => node && node.setAttribute('href', bookmarklet)}>Save Recipe to Cookmatic</Bookmarklet>
        {' <- Drag this link to your bookmark bar.'}
      </BookmarkletText>

      <BookmarkletTextField>{bookmarklet}</BookmarkletTextField>
    </BookmarkletContainer>
  </>
}

function mapStateToProps(state) {
  return {
    error: state.error,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createRecipe,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Save)