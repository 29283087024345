import * as constants from '../../constants/helpers';
import callApi from '../base';

export function addToList(slug) {
  const url = constants.API_ROOT_URL + 'recipes/' + slug + '/list'
  return callApi(url, 'POST')
}

export function removeFromList(slug) {
  const url = constants.API_ROOT_URL + 'recipes/' + slug + '/list'
  return callApi(url, 'DELETE')
}