import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pickBy } from 'lodash'

import Recipes from '../Shared/recipes'
import SavedItem from './saved_item'

import { loadSaves } from '../../actions/save'

const SectionHeader = styled.h2`
  font-size: 36px;
  margin-bottom: 50px;
`

class SavedRecipes extends Component {
  componentDidMount() {
    this.props.loadSaves()
  }

  render() {
    return(
      <>
        <SectionHeader>Saved Recipes</SectionHeader>
        {this.props.recipes.length > 0 ? (
          <Recipes recipes={this.props.recipes} Component={SavedItem} />
        ) : (
          <h3>This is where your saved recipes will go once you save some.</h3>
        )}
      </>
    )
  }
}

SavedRecipes.propTypes = {
  recipes: PropTypes.array.isRequired,
  loadSaves: PropTypes.func.isRequired,
}

export function mapStateToProps(state) {
  return {
    recipes: filter(state.recipes)
  }
}

const filter = (object) => {
  const savedOrList = pickBy(object, (value, key) => (value.list || value.saved))
  return Object.values(savedOrList)
}


export function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadSaves }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedRecipes)