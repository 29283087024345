import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ShoppingListToggle from '../Shared/shoppingListToggle'

const SubHeader = styled.h3`
  font-family: 'Crimson Text', serif;
  font-size: 28px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
`

const HeaderContainer = styled.div`
  overflow: hidden;
`

const ListViewLink = styled(Link)`
  float: left;
  text-decoration: none;
`

const AddToShoppingList = styled(ShoppingListToggle)`
  float: right;
  font-size: 16px;
  position: relative;
  top: 7px;
`

const ListItem = styled.li`
  margin-bottom: 10px;
  position: relative;

`

const Ingredients = ({ recipe, ingredients }) => <ul>
  <SubHeader>
    <IngredientHeader {...recipe} />
  </SubHeader>
  {ingredients.map((ingredient, index) =>
    <ListItem key={index}>
      {ingredient.input}
    </ListItem>
  )}
</ul>

const IngredientHeader = (props) => <HeaderContainer>
  <ListViewLink to={'/recipes/' + props.slug + '/ingredients'}>Ingredients</ListViewLink>
  <AddToShoppingList
    {...props}
    removeText='remove from shopping list'
    addText='add to shopping list'
  />
</HeaderContainer>

export default Ingredients