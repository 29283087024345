import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import includes from 'lodash/includes'
import { GridContainer } from '../Style/grid'
import RecipeListItem from '../Shared/recipeListItem'
import { loadTag } from '../../actions/tag'

const TagTitle = styled.h1`
  font-family: 'Crimson Text', serif;
  font-size: 42px;
  margin: 25px 0 50px;
  text-transform: capitalize;
`

class Tag extends Component {
  componentDidMount() {
    this.props.onInitialize(this.props.match.params.slug)
  }

  render() {
    return(
      <div>
        <TagTitle>{this.formattedTitle()} Recipes</TagTitle>
        <GridContainer recipes={this.props.recipes} Component={RecipeListItem} />
      </div>
    )
  }

  formattedTitle() {
    return this.props.match.params.slug.replace(/-/g, ' ')
  }
}

Tag.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  recipes: PropTypes.array.isRequired
}

const filter = (recipes, tag) => {
  const array = Object.values(recipes)
  return array.filter(recipe => {
    const slugs = recipe.tags.map(tag => tag.slug)
    return includes(slugs, tag)
  })
}

function mapStateToProps(state, ownProps) {
  return {
    recipes: filter(state.recipes, ownProps.match.params.slug)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: bindActionCreators(loadTag, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tag)