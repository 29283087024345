import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { GridContainer } from '../Style/grid'
import RecipeListItem from '../Shared/recipeListItem'
import { loadRecipes } from '../../actions/recipes'

class Home extends Component {

  componentDidMount() {
    this.props.onInitialize()
  }

  render() {
    return (
      <GridContainer recipes={this.props.recipes} Component={RecipeListItem} />
    )
  }
}

Home.propTypes = {
  recipes: PropTypes.array.isRequired,
  onInitialize: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    recipes: Object.values(state.recipes)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: bindActionCreators(loadRecipes, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)