import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history';
import './reset.css'
import './index.css';
import App from './components/App';
import configureStore from './store/configureStore'
import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory();
const store = configureStore(history);
const flash = store.getState().flash

ReactDOM.render(
  <Provider store={store} flash={flash}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();

if (module.hot){
  module.hot.accept()
}
