import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import thunk from 'redux-thunk';

export default function configureStore(history) {
  const store = createStore(
    createRootReducer(history),
    applyMiddleware(thunk, routerMiddleware(history))
  )

  return store
}