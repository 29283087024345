import * as actionTypes from '../../constants/actionTypes'

export const setError = (error) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ERROR,
    payload: error,
  })
}

export const clearError = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERROR
  })
}