import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { addToList, removeFromList } from '../../actions/list'

class ShoppingListToggle extends Component {
  constructor() {
    super()
    this.addToList = this.addToList.bind(this)
    this.removeFromList = this.removeFromList.bind(this)
  }

  render() {
    const url = '/recipes/' + this.props.slug

    if (this.props.list) {
      return(
        <a
          href={url}
          className={this.props.className}
          onClick={this.removeFromList}
        >{this.props.removeText}</a>
      )
    } else {
      return(
        <a
          href={url}
          className={this.props.className}
          onClick={this.addToList}
        >{this.props.addText}</a>
      )
    }
  }

  addToList(event) {
    event.preventDefault()
    this.props.addToList(this.props.slug)
  }

  removeFromList(event) {
    event.preventDefault()
    this.props.removeFromList(this.props.slug)
  }
}

ShoppingListToggle.propTypes = {
  addToList: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addToList, removeFromList }, dispatch)
}

export default connect(null, mapDispatchToProps)(ShoppingListToggle)