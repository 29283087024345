import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/items'

export const loadItems = () => (dispatch) => {
  return api.loadItems().then(
    response => {
      dispatch({
        type: actionTypes.LOAD_ITEMS_SUCCESS,
        payload: response.items,
      })
      dispatch({
        type: actionTypes.LOAD_REOCCURING,
        payload: response.reoccuring.map(r => r.name),
      })
      dispatch({
        type: actionTypes.LOAD_PANTRY,
        payload: response.pantry.map(p => p.name),
      })
    }
  )
}

export const saveItem = (body) => (dispatch) => {
  api.saveItem(body)
  dispatch({
    type: actionTypes.SAVE_ITEM,
    payload: body,
  })
}

export const updateItems = (items, key, value) => (dispatch) => {
  items.forEach(item => {
    api.updateItem(item.id, { [key]: value })
    const updatedItem = {...item, [key]: value }
    dispatch({
      type: actionTypes.UPDATE_ITEM,
      payload: updatedItem
    })
  })
}

export const deleteItems = (items) => (dispatch) => {
  items.forEach(item => {
    api.deleteItem(item.id)
    dispatch({
      type: actionTypes.DELETE_ITEM,
      payload: item.id,
    })
  })
}