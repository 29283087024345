import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { createRecipe } from '../../actions/recipes'
import { toggleNav } from '../../actions/nav'
import { search } from '../../actions/search'
import { url } from '../../library/regex'
import * as constants from '../../constants/helpers'
import Search from './search'
import Flash from './flash'

const HeaderContainer = styled.div`
  transform: translate(${props => props.nav ? constants.NAV_WIDTH : '1px'});
  transition: transform .2s ease-in-out;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 3;
`

const MainHeaderContainer = styled.div`
  position: relative;
`

const HeaderLogo = styled.h1`
  float: left;
  border-right: 1px solid #ccc;
  margin: 0;
  padding: 18px;
  font-size: 35px;

  @media (max-width: 900px) {
    font-size: 0;

    :before {
      content: 'c';
      font-size: 16px;
      font-weight: bold;
    }
  }
`

const LogoLink = styled(Link)`
  color: black;
  text-decoration: none;
`

const OmniNav = styled.div`
  overflow: hidden;

  @media (max-width: 600px) {
    overflow: initial;
  }
`

const OmniNavInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 24px;
  padding: 22px 100px 21px 18px;
  background-color: white;

  @media (max-width: 900px) {
    font-size: 14px;
    padding: 18px 18px 14px;
  }

  @media (max-width: 600px) {
    border-top: 1px solid #ccc;
    display: none;
  }
`

const Hamburger = styled.a`
  position: absolute;
  right: 20px;
  top: 25px;
  text-decoration: none;

  :before {
    content: '☰';
    margin-right: 5px;
  }

  @media (max-width: 900px) {
    top: 18px;
  }
`

class Header extends Component {
  constructor() {
    super()
    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {
    document.getElementById('omninav-input').focus()
  }

  render() {
    return(
      <HeaderContainer nav={this.props.nav}>
        <Flash />
        <MainHeaderContainer>
          <LogoLink to='/'>
            <HeaderLogo>Cookmatic</HeaderLogo>
          </LogoLink>
          <OmniNav>
            <OmniNavInput
              id='omninav-input'
              type='url' name='url'
              placeholder='Search for recipes or ingredients'
              onInput={this.handleInput}
              required
            />
          </OmniNav>
          <Hamburger href='#' onClick={this.props.toggleNav}>Menu</Hamburger>
        </MainHeaderContainer>
        <Search />
      </HeaderContainer>
    )
  }

  handleInput(event) {
    event.preventDefault()
    const value = event.target.value

    if (url.test(value)) {
      this.props.createRecipe({ url: value })
    } else {
      if (value.length > 2) {
        this.props.search(value)
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    nav: state.nav,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createRecipe, toggleNav, search }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)