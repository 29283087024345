import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import sampleSize from 'lodash/sampleSize';
import * as constants from '../../constants/helpers';

const ListItem = styled.li`
  display: inline-block;
  width: 22.75%;
  vertical-align: top;
  margin: 0 3% 5% 0;

  :nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: 900px) {
    width: 48.5%;

    :nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 0 5% 0;
  }
`;

const NoTextDecoration = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  height: 70vh;
  object-fit: cover;

  img {
    height: 100%;
    transform: translate(-50%);
    margin-left: 50%;
  }
`;

const MetaContainer = styled.div`
  padding: 15px 15px 15px 0;
`;

const RecipeTitle = styled.h2`
  font-family: 'Crimson Text', serif;
  text-decoration: none;
  font-size: 24px;
  line-height: 1.3;
`;

const TagColorItem = styled.li`
  background-color: ${props => props.tagColor || constants.DEFAULT_TAG_COLOR};
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  font-size: 0;
`;

const RecipeListItem = ({ recipe }) => {
  return(
    <ListItem>
      <NoTextDecoration to={'/recipes/' + recipe.slug}>
        <ImageContainer><img alt={recipe.title} src={recipe.image} /></ImageContainer>
        <MetaContainer>
          <RecipeTitle>{recipe.title}</RecipeTitle>
          <TagColors tags={recipe.tags} />
        </MetaContainer>
      </NoTextDecoration>
    </ListItem>
  )
}

class TagColors extends Component {

  render() {
    return(
      <ul>
        {this.tagColors().map((color, index) =>
          <TagColorItem tagColor={color} key={index}>{color}</TagColorItem>
        )}
      </ul>
    )
  }

  tagColors() {
    const existing = this.props.tags.filter(tag => tag.color).map(tag => tag.color)
    let colors = sampleSize(existing, 3)
    return colors
  }
}

export default RecipeListItem;