import * as constants from '../../constants/helpers'
import callApi from '../base'

export function addToReoccuring(body) {
  const url = constants.API_ROOT_URL + 'reoccurings'
  return callApi(url, 'POST', body)
}

export function removeFromReoccuring(body) {
  const url = constants.API_ROOT_URL + 'reoccurings'
  return callApi(url, 'DELETE', body)
}