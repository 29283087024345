import * as localStorage from '../library/localStorage';

export default function callApi(url, method = 'GET', body = undefined) {
  return fetch(url, {
    method: method,
    headers: setHeaders(),
    body: JSON.stringify(body)
  }).then(handleResponse)
}

const setHeaders = () => {
  var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (!!localStorage.getItem('user')) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('user').jwt
  }

  return headers
}

const handleResponse = (response) => {
  if (response.status === 204) return '';
  if (response.status === 401) {
    const path = encodeURIComponent(window.location.pathname)
    window.location = '/log-in?redirect=' + path
    return false;
  }
  if (!response.ok) { throw response.json() }

  return response.json()
}