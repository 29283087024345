import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const pantry = (state = initialState.pantry, action) => {
  switch(action.type) {
    case actionTypes.LOAD_PANTRY:
      return action.payload
    case actionTypes.ADD_TO_PANTRY:
      return [...state, action.payload]
    case actionTypes.REMOVE_FROM_PANTRY:
      return state.filter(i => i !== action.payload)
    default:
      return state
  }
}

export default pantry