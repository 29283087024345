import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/reoccuring'

export const addToReoccuring = (body) => (dispatch) => {
  return api.addToReoccuring(body).then(
    () => (
      dispatch({
        type: actionTypes.ADD_TO_REOCCURING,
        payload: body.name
      })
    )
  )
}

export const removeFromReoccuring = (body) => (dispatch) => {
  return api.removeFromReoccuring(body).then(
    () => (
      dispatch({
        type: actionTypes.REMOVE_FROM_REOCCURING,
        payload: body.name
      })
    )
  )
}