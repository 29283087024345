import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const flash = (state = initialState.flash, action) => {
  switch(action.type) {
    case actionTypes.DISPLAY_FLASH:
      return action.message
    case actionTypes.CLEAR_FLASH:
      return initialState.flash
    default:
      return state;
  }
}

export default flash;