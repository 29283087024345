import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RecipeMeta from './meta'
import Ingredients from './ingredients'
import Instructions from './instructions'
import TagList from './tag_list'

const RecipeHeader = styled.div`
  overflow: hidden;
`

const RecipeData = styled.div`
  float: left;
`

const NoTextDecoration = styled.a`
  text-decoration: none;
`

const RecipeTitle = styled.h1`
  font-family: 'Crimson Text', serif;
  font-size: 42px;
  margin: 25px 0 9px;
`

const AuthorData = styled.h2`
  color: #777;
  font-size: 12px;
  margin: 0 0 50px 4px;

  a {
    color: #777;
  }
`

const RecipeImage = styled.img`
  width: 100%;
  max-height: 450px;
  object-fit: cover;
`

const RecipeDetails = styled.div`
  margin-top: 25px;
  overflow: hidden;
  line-height: 1.6;
`

const MetaContainer = styled.div`
  float: left;
  width: 30%;
  margin-right: 5%;

  @media (max-width: 900px) {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 50px;
  }
`

const CannotParse = styled.h2`
  font-family: 'Crimson Text', serif;
  font-size: 24px;
  margin: 25px 0 9px;
`

const host = (url) => {
  if (url) {
    return new URL(url).host.replace('www.', '')
  }
}

const RecipeShow = ({ recipe, ingredients }) => {
  return(
    <div className='recipe-show'>
      <RecipeHeader>
        <RecipeData>
          <NoTextDecoration href={recipe.url} target='_blank' rel='noopener noreferrer'>
            <RecipeTitle>{recipe.title}</RecipeTitle>
          </NoTextDecoration>
          <AuthorData>
            from <a href={recipe.url} target='_blank' rel='noopener noreferrer'>{host(recipe.url)}</a>{' '}
            {recipe.author_name && <>
              by <a href={recipe.author_url} target='_blank' rel='noopener noreferrer'>{recipe.author_name}</a>{' '}
            </>}
            {recipe.published_at && <>
              on {new Date(recipe.published_at).toLocaleDateString()}.
            </>}
          </AuthorData>
        </RecipeData>
      </RecipeHeader>

      <RecipeInfo recipe={recipe} ingredients={ingredients} />
    </div>
  )
}

const RecipeInfo = ({ recipe, ingredients }) => {
  if (recipe.parsed) {
    return <>
      <RecipeMeta recipe={recipe} />
      <RecipeImage src={recipe.image} alt={recipe.title} />
      <RecipeDetails>
        <MetaContainer>
          <Ingredients recipe={recipe} ingredients={ingredients} />
          <TagList tags={recipe.tags} />
        </MetaContainer>
        <Instructions recipe={recipe} ingredients={ingredients} />
      </RecipeDetails>
    </>
  } else {
    return(
      <CannotParse>
        We were unable to parse this recipe. View it <a href={recipe.url}>here</a>.
      </CannotParse>
    )
  }
}
RecipeShow.propTypes = {
  recipe: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
};

export default RecipeShow