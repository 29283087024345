import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const user = (state = initialState.user, action) => {
  switch(action.type) {
    case actionTypes.LOAD_USER_SUCCESS:
      return action.response
    default:
      return state;
  }
}

export default user;