export const handleRecipeToggle = (state, { slug }, toggleKey = 'saved') => {
  return Object.keys(state).reduce((map, key) => {
    const obj = key === slug ? { ...state[key], ...{ [toggleKey]: !state[key][toggleKey] }} : state[key];
    map[key] = obj;
    return map;
  }, {})
}

export const handleIngredientDoneToggle = (state, id) => {
  return Object.keys(state).reduce((map, key) => {
    const obj = Number(key) === id ? { ...state[key], ...{ done: !state[key]['done'] }} : state[key];
    map[key] = obj;
    return map;
  }, {})
}