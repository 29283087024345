import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Form from '../Form'
import { saveItem } from '../../actions/items'

const StyledForm = styled(Form)`
  position: relative;
  margin-bottom: 15px;
`

const Label = styled.label`
  position: absolute;
  font-size: 12px;
  text-transform: uppercase;
  left: 15px;
  top: -6px;
  background: white;
  padding: 2px;
`

const inputStyles = css`
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  outline: none;
  appearance: none;
`

const width = '107px'
const margin = '5px'

const Input = styled.input`
  ${inputStyles}
  border: 1px solid #ccc;
  border-radius: none;
  padding: 15px;
  width: calc(100% - ${width} - ${margin})
`

const Button = styled.input`
  ${inputStyles}
  border: none;
  background: black;
  color: white;
  padding: 16px 32px;
  margin-left: ${margin};
  float: right;
  width: ${width};
  cursor: pointer;
`

const NewItemForm = ({ saveItem }) => {
  const defaultInput = ''
  const [input, setInput] = useState(defaultInput)

  const handleSubmit = () => {
    const downcase = input.toLowerCase()
    saveItem({ name: downcase, input: downcase, quantity: null, unit: null })
    setInput(defaultInput)
  }

  return(
    <StyledForm action={handleSubmit}>
      <Label htmlFor='input'>Add to your shopping list</Label>
      <Input
        type='text'
        placeholder='ex: Chocolate, Flour, Gallon of Milk, etc...'
        name='input'
        autoFocus={true}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Button type='submit' value='Add' />
    </StyledForm>
  )
}

NewItemForm.propTypes = {
  saveItem: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveItem,
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(NewItemForm)