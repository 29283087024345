import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const reoccuring = (state = initialState.reoccuring, action) => {
  switch(action.type) {
    case actionTypes.LOAD_REOCCURING:
      return action.payload
    case actionTypes.ADD_TO_REOCCURING:
      return [...state, action.payload]
    case actionTypes.REMOVE_FROM_REOCCURING:
      return state.filter(i => i !== action.payload)
    default:
      return state
  }
}

export default reoccuring