import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logIn } from '../../actions/auth'
import { setFlash } from '../../actions/flash'
import {
  Container,
  StyledForm,
  Header,
  Input,
  Button,
  Alternates
} from './style'

class LogIn extends Component {

  componentDidMount() {
    const search = this.props.location.search
    if (!!search && search !== ' ') { this.handleRedirect(search) }
  }

  handleRedirect(search) {
    this.props.setFlash('Sorry, you need to log in first!')
  }

  render() {
    return(
      <Container>
        <StyledForm action={this.props.logIn}>
          <Header>Log In</Header>
          <Input type='email' placeholder='Email Address' name='email' autoComplete='username' />
          <Input type='password' placeholder='Password' name='password' autoComplete='current-password' />
          <Button type='submit' value='Log In' />
          <Alternates>
            Don't have an account? <Link to='/sign-up'>Sign Up</Link>.
          </Alternates>
        </StyledForm>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logIn: bindActionCreators(logIn, dispatch),
    setFlash: bindActionCreators(setFlash, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(LogIn)