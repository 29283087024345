import * as constants from '../../constants/helpers';
import callApi from '../base';

export function loadUser() {
  const url = constants.API_ROOT_URL + 'users'
  return callApi(url)
}

export function saveUser(body) {
  const url = constants.API_ROOT_URL + 'users'
  return callApi(url, 'PUT', body)
}