import * as constants from '../../constants/helpers';
import callApi from '../base';

export function loadRecipes() {
  const url = constants.API_ROOT_URL + 'recipes'
  return callApi(url)
}

export function loadRecipe(slug) {
  const url = constants.API_ROOT_URL + 'recipes/' + slug
  return callApi(url)
}

export function createRecipe(body) {
  const url = constants.API_ROOT_URL + 'recipes'
  return callApi(url, 'POST', body)
}