import Form from '../Form'
import styled from 'styled-components'

export const Container = styled.div`
  background: #F5F5F5;
  height: 90vh;
  padding: 10vh 0;

  @media (max-width: 600px) {
    padding: 0;
  }
`

export const StyledForm = styled(Form)`
  background: #fff;
  width: 450px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

export const Header = styled.h1`
  font-family: 'Crimson Text', serif;
  font-size: 36px;
  margin-bottom: 25px;
`

export const Input = styled.input`
  font-family: 'Lato', sans-serif;
  display: block;
  font-size: 24px;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
`

export const Button = styled.input`
  font-family: 'Crimson Text', serif;
  appearance: none;
  border: none;
  font-size: 24px;
  padding: 10px 30px;
  cursor: pointer;
  background: #F5F5F5;
  width: 100%;
`

export const Alternates = styled.div`
  font-family: 'Crimson Text', serif;
  font-size: 12px;
  margin-top: 25px;
`