import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const items = (state = initialState.items, action) => {
  switch(action.type) {
    case actionTypes.LOAD_ITEMS_SUCCESS:
      return action.payload
    case actionTypes.SAVE_ITEM:
      return [action.payload, ...state]
    case actionTypes.UPDATE_ITEM:
      return updateItems(state, action.payload)
    case actionTypes.DELETE_ITEM:
      return state.filter(item => item.id !== action.payload)
    default:
      return state;
  }
}

function updateItems(state, updatedItem) {
  return state.map(item =>
    (item.id === updatedItem.id) ? updatedItem : item
  )
}

export default items