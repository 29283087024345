import * as actionTypes from '../../constants/actionTypes';

export const setFlash = text => (dispatch) => {
  dispatch({
    type: actionTypes.DISPLAY_FLASH,
    message: text
  })
}

export const clearFlash = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_FLASH
  })
}
