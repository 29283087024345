import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NewItemForm from './newItemForm'
import IngredientListMenu from './menu'
import { updateItems, loadItems } from '../../actions/items'

const Header = styled.div`
  font-family: 'Crimson Text', serif;
  margin: 25px 0 50px;
  font-size: 42px;

  :after {
    content: '';
    clear: both;
    display: table;
  }
`

const Title = styled.h1`
  float: left;
`

const ShowForm = styled.h2`
  float: right;
  position: relative;
  top: -4px;
  cursor: pointer;
`

const ListItem = styled.li`
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-family: 'Crimson Text', serif;
  position: relative;
  height: 76px;
  font-size: 24px;

  :first-of-type {
    border-top: 1px solid #ccc;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Layer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const TopLayer = styled(Layer)`
  z-index: 2;
  background: white;
  font-size: 24px;
  padding: 25px 15px 25px 0;

  transform: ${props => props.open ? 'translateX(-100%)' : 'translateX(0)'};
  transition: transform 0.2s ease-in-out;

  :hover {
    background: #F8F8F8;
  }
`

const Reoccuring = styled.span`
  margin-left: 10px;
`

const Pantry = styled.span`
  margin-left: 10px;
`

const BottomLayer = styled(Layer)`
  z-index: 1;
`

const ListItemContent = styled.h3`
  display: inline;
  :before {
    content: '\u2714';
    font-size: 24px;
    margin-right: 25px;
    float: left;
  }
`;

const PurchasedListItemContent = styled(ListItemContent)`
  color: #ccc;
  text-decoration: line-through;
`;

const MenuToggle = styled.h4`
  font-size: 0;
  position: absolute;
  right: 0;
  top: 18px;

  :before {
    content: '⋮';
    font-size: 36px;
    padding: 10px 0 10px 10px;
  }
`

class ShoppingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      openMenus: [],
    }
  }

  componentDidMount() {
    this.props.loadItems()
  }

  render() {
    const { items, updateItems, reoccuring, pantry } = this.props
    const { openMenus, showForm } = this.state

    return <>
      <Header>
        <Title>Your Shopping List</Title>
        <ShowForm onClick={() => this.setState({ showForm: true })}>+</ShowForm>
      </Header>
      {showForm &&
        <NewItemForm />
      }
      <ul>
        {Object.entries(items).map(([name, array], index) => {
          const item = array[0]
          const purchased = !!item.purchased_at
          const Tag = purchased ? PurchasedListItemContent : ListItemContent

          return(
            <ListItem key={index} onClick={() => {
              const value = !!array[0].purchased_at ? null : new Date()
              updateItems(array, 'purchased_at', value)
            }}>
              <TopLayer open={openMenus.includes(name)}>
                <Tag key={index}>
                  {this.displayName(array)}
                </Tag>
                {reoccuring.includes(name) &&
                  <Reoccuring>⟳</Reoccuring>
                }
                {pantry.includes(name) &&
                  <Pantry>♾</Pantry>
                }
                <MenuToggle onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  this.toggleMenu(name)
                }}>Menu</MenuToggle>
              </TopLayer>
              <BottomLayer>
                <IngredientListMenu
                  items={array}
                  reoccuring={reoccuring}
                  pantry={pantry}
                />
              </BottomLayer>
            </ListItem>
          )
        })}
      </ul>
    </>
  }


  displayName(array) {
    if (array.length > 1) {
      return this.combineItems(array)
    } else {
      return array[0].input
    }
  }

  combineItems(array) {
    const name = array[0].name

    const quantitiesByUnit = array.reduce((acc, item) => {
      const quantity = item.quantity || '1.0'
      const float = parseFloat(quantity)
      acc[item.unit] = acc[item.unit] ? acc[item.unit] + float : float
      return acc
    }, {})

    const quantityArray = Object.keys(quantitiesByUnit).reduce((acc, key) => {
      const quantity = quantitiesByUnit[key]
      const plural = quantity > 1 ? 's' : ''
      const string = (key !== 'null' && key !== 'undefined') ? `${quantity} ${key}${plural} of ${name}` : `${quantity} ${name}${plural}`
      acc.push(string)
      return acc
    }, [])

    return `${quantityArray.join(' + ')} *(${array.length})`
  }

  toggleMenu(name) {
    const { openMenus } = this.state
    if (openMenus.includes(name)) {
      this.setState({
        openMenus: openMenus.filter(m => m !== name)
      })
    } else {
      this.setState({
        openMenus: [...openMenus, name]
      })
    }
  }
}

const prepare = (items, pantry) => {
  // first we mark all items in the pantry as
  // purchased so they are crossed out already.
  const pantriedItems = items.reduce((acc, item) => {
    const value = pantry.includes(item.name) ? {...item, purchased_at: 'pantry' } : item
    return [...acc, value]
  }, [])

  // turns items into an object with the name as the key
  // and an array of the items with that for a name as the value
  return pantriedItems.reduce((acc, item) => {
    const value = acc[item.name] ? [...acc[item.name], item] : [item]
    return {...acc, [item.name]: value }
  }, {})
}

ShoppingList.propTypes = {
  items: PropTypes.object.isRequired,
  reoccuring: PropTypes.array.isRequired,
  pantry: PropTypes.array.isRequired,

  updateItems: PropTypes.func.isRequired,
  loadItems: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const pantry = state.pantry

  return {
    items: prepare(state.items, pantry),
    reoccuring: state.reoccuring,
    pantry: pantry,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadItems,
    updateItems,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingList)