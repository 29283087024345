import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as constants from '../../constants/helpers';

const MoveableContainer = styled.div`
  transform: translate(${props => props.nav ? constants.NAV_WIDTH : '0'});
  transition: transform .2s ease-in-out;
  position: relative;
  z-index: 2;
  background: #fff;
  min-height: 100vh;
`;

class MovedByNav extends Component {

  render() {
    return(
      <MoveableContainer nav={this.props.nav}>
        {this.props.children}
      </MoveableContainer>
    )
  }
}

function mapStateToProps(state, ownProps) {
  // because of, I guess the fact that we are using this
  // component to wrap routes, then pass them as children
  // in the above render, and also maybe possibly because
  // of how 'connected-react-router' works (which, as I
  // understand it, is using redux store to hold the state
  // of the history and router) we need to pass `router `
  // in as well. I gathered this from
  // https://github.com/supasate/connected-react-router/issues/130#issuecomment-415801233
  // if you remove the router part here, it wont work anymore.

  return {
    router: state.router,
    nav: state.nav,
  }
}

export default connect(mapStateToProps)(MovedByNav);