import * as actionTypes from '../../constants/actionTypes'
import initialState from '../initialState'

const error = (state = initialState.error, action) => {
  switch(action.type) {
    case actionTypes.SET_ERROR:
      return action.payload
    case actionTypes.CLEAR_ERROR:
      return initialState.error
    default:
      return state
  }
}

export default error