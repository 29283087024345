import * as constants from '../constants/helpers';

export function setItem(key, data) {
  var object = {};
  object[key] = data;

  localStorage.setItem(
    constants.LOCAL_STORAGE_KEY,
    JSON.stringify(object)
  )
}

export function getItem(key) {
 return JSON.parse(
    localStorage.getItem(constants.LOCAL_STORAGE_KEY) || '{}'
  )[key]
}