import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SaveToggle from '../Shared/saveToggle'

const MetaList = styled.ul`
  border: 1px solid #ccc;
  overflow: hidden;

  @media (max-width: 600px) {
    border-width: 1px 1px 0 1px;
  }
`

const MetaListItem = styled.li`
  display: inline-block;
  padding: 25px 50px 25px 15px;
  border-right: 1px solid #ccc;

  label {
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 5px;
    display: block;
  }

  @media (max-width: 600px) {
    display: block;
    border-bottom: 1px solid #ccc;
    border-right: none;
  }
`

const SaveContainer = styled.li`
  display: inline-block;
  float: right;
  padding: 33px 10px;
`

const MetaField = ({ content, label }) => {
  return(
    <MetaListItem>
      <label>{label}</label>
      <h4>{content}</h4>
    </MetaListItem>
  )
}

class RecipeMeta extends Component {
  render() {
    const recipe = this.props.recipe;
    return(
      <MetaList>
        {recipe.yield &&
          <MetaField label='Yields' content={recipe.yield} />
        }
        {this.timeComponents(recipe)}
        <SaveContainer>
          <SaveToggle
            {...recipe}
            removeText='recipe saved'
            addText='save this recipe'
          />
        </SaveContainer>
      </MetaList>
    )
  }

  timeComponents(recipe) {
    if (!recipe.active_time && !recipe.total_time) { return null }
    if (recipe.active_time === recipe.total_time) {
      return(
        <MetaField label='Time' content={recipe.active_time} />
      )
    } else {
      return(
        <span>
          {recipe.active_time &&
            <MetaField label='Active Time' content={recipe.active_time} />
          }
          {recipe.cook_time &&
            <MetaField label='Cook Time' content={recipe.cook_time} />
          }
          {recipe.total_time &&
            <MetaField label='Total Time' content={recipe.total_time} />
          }
        </span>
      )
    }
  }
}

RecipeMeta.propTypes = {
  recipe: PropTypes.object.isRequired
}

export default RecipeMeta