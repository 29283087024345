import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import recipes from './recipes'
import auth from './auth'
import nav from './nav'
import search from './search'
import flash from './flash'
import user from './user'
import items from './items'
import error from './error'
import reoccuring from './reoccuring'
import pantry from './pantry'

export default (history) => combineReducers({
  router: connectRouter(history),
  currentUser: auth,
  recipes,
  nav,
  search,
  flash,
  user,
  items,
  error,
  reoccuring,
  pantry,
})