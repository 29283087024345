import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { updateItems, deleteItems } from '../../actions/items'
import { addToPantry, removeFromPantry } from '../../actions/pantry'
import { addToReoccuring, removeFromReoccuring } from '../../actions/reoccuring'

const Menu = styled.ul`

`

const sharedStyles = css`
  display: inline-block;
  width: 25%;
  text-align: center;
  color: white;
  padding: 25px 0;
  height: 74px;
  vertical-align: top;

  @media (max-width: 600px) {
    padding: 30px 0;
  }
`

const MenuItem = styled.li`
  ${sharedStyles}
`

const ViewRecipe = styled(Link)`
  ${sharedStyles}
  background: lavender;
  text-decoration: none;
`

const AddToPantry = styled(MenuItem)`
  background: lightgreen;

  :before {
    content: '♾';
    margin-right: 5px;
  }
`

const MakeReoccuring = styled(MenuItem)`
  background: lightblue;

  :before {
    content: '⟳';
    margin-right: 5px;
  }
`

const Delete = styled(MenuItem)`
  background: salmon;
`

class IngredientListMenu extends Component {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this);
  }

  render() {
    const { items, reoccuring, pantry } = this.props
    const item = items[0]

    return(
      <Menu>
        <ViewRecipe to={'/recipes/' + item.recipe}>View Recipe</ViewRecipe>
        <ToggleableItem
          item={item}
          collection={pantry}
          Component={AddToPantry}
          trueText='Remove from Pantry'
          falseText='Add to Pantry'
          addAction={this.props.addToPantry}
          removeAction={this.props.removeFromPantry}
        />
        <ToggleableItem
          item={item}
          collection={reoccuring}
          Component={MakeReoccuring}
          trueText='Stop Reoccuring'
          falseText='Make Reoccuring'
          addAction={this.props.addToReoccuring}
          removeAction={this.props.removeFromReoccuring}
        />
        <Delete onClick={this.delete}>Delete</Delete>
      </Menu>
    )
  }

  delete(event) {
    event.preventDefault()
    event.stopPropagation()
    const { items, deleteItems } = this.props

    if (window.confirm('Are you sure?')) {
      deleteItems(items)
    }
  }
}

const ToggleableItem = ({ item, collection, Component, trueText, falseText, addAction, removeAction }) => {
  const { id: item_id, name } = item
  const isInCollection = collection.includes(name)

  return(
    <Component onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isInCollection) {
        removeAction({ item_id, name })
      } else {
        addAction({ item_id, name })
      }
    }}>{isInCollection ? trueText : falseText}</Component>
  )
}

IngredientListMenu.propTypes = {
  items: PropTypes.array.isRequired,
  reoccuring: PropTypes.array.isRequired,
  pantry: PropTypes.array.isRequired,

  updateItems: PropTypes.func.isRequired,
  deleteItems: PropTypes.func.isRequired,
  addToPantry: PropTypes.func.isRequired,
  removeFromPantry: PropTypes.func.isRequired,
  addToReoccuring: PropTypes.func.isRequired,
  removeFromReoccuring: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateItems,
    deleteItems,
    addToPantry,
    removeFromPantry,
    addToReoccuring,
    removeFromReoccuring,
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(IngredientListMenu);