import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { save, unsave } from '../../actions/save';

class SaveToggle extends Component {
  constructor() {
    super()
    this.handleSave = this.handleSave.bind(this);
    this.handleUnsave = this.handleUnsave.bind(this);
  }

  render() {
    const url = '/recipes/' + this.props.slug;

    if (this.props.saved) {
      return(
        <a
          href={url}
          className={this.props.className}
          onClick={this.handleUnsave}
        >{this.props.removeText}</a>
      )
    } else {
      return(
        <a
          href={url}
          className={this.props.className}
          onClick={this.handleSave}
        >{this.props.addText}</a>
      )
    }
  }

  handleSave(event) {
    event.preventDefault();
    this.props.save(this.props.slug);
  }

  handleUnsave(event) {
    event.preventDefault();
    this.props.unsave(this.props.slug);
  }
}

SaveToggle.propTypes = {
  save: PropTypes.func.isRequired,
  unsave: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ save, unsave }, dispatch)
}

export default connect(null, mapDispatchToProps)(SaveToggle);