import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RecipeShow from './show'
import { loadRecipe } from '../../actions/recipes'
import initialState from '../../reducers/initialState'

class Recipe extends Component {
  componentDidMount() {
    if (!this.props.recipe.id) {
      this.props.onInitialize(this.props.match.params.slug)
    }
  }

  render() {
    return <RecipeShow {...this.props} />
  }
}

Recipe.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
}

function mapStateToProps(state, ownProps) {
  const recipe = filterRecipe(state.recipes, ownProps.match.params.slug) || initialState.recipe

  return {
    recipe: recipe,
    ingredients: recipe.ingredients,
  }
}

const filterRecipe = (recipes, slug) => {
  return recipes[slug]
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: bindActionCreators(loadRecipe, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipe)