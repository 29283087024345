import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import range from 'lodash/range'
import Form from '../Form'
import { loadUser, saveUser } from '../../actions/user'

const SettingsContainer = styled.div`
  font-family: 'Crimson Text', serif;
  font-size: 24px;
  background: #F5F5F5;
  padding: 25px;
  margin-bottom: 25px;
`

const SettingsHeader = styled.h1`
  font-size: 36px;
  margin-bottom: 50px;
`

const SettingsLine = styled.h2`
  margin-bottom: 25px;
`

const Select = styled.select`
  font-family: 'Crimson Text', serif;
  font-size: 24px;
  appearance: none;
  border: none;
  border-bottom: 2px solid #242424;
  border-radius: 0;
`

const Email = styled.input`
  font-family: 'Crimson Text', serif;
  font-size: 24px;
  background: none;
  border: none;
  border-bottom: 2px solid #242424;
  border-radius: 0;
  min-width: 300px;
`

const Submit = styled.input`
  font-family: 'Crimson Text', serif;
  font-size: 24px;
  background-color: #242424;
  color: #fff;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
`

const Action = styled.li`
  margin-bottom: 15px;
`

class Settings extends Component {

  componentDidMount() {
    this.props.onInitialize()
  }

  render() {
    return <>
      <SettingsContainer>
        <SettingsHeader>Settings</SettingsHeader>
        <Form action={this.props.saveUser}>
          <SettingsLine>
            I want to cook
            {' '}
            <Select name='meals' defaultValue={this.props.user.meals} key={this.props.user.meals}>
              {range(1, 22).map(number =>
                <option key={number} value={number}>{number}</option>
              )}
            </Select>
            {' '}
            meals at home every week.
          </SettingsLine>
          <SettingsLine>
            I want my grocery lists sent to
            {' '}
            <Email type='email' name='email' defaultValue={this.props.user.email} key={this.props.user.email} />
          </SettingsLine>

          <Submit type='submit' value='Save' />
        </Form>
      </SettingsContainer>

      <SettingsContainer>
      <SettingsHeader>Other options</SettingsHeader>
        <ul>
          <Action>
            <Link to='/pantry'>Your pantry ingredients</Link>
          </Action>
          <Action>
            <Link to='/invite'>Invite people to your household</Link>
          </Action>
          <Action>
            <Link to='/bookmarklet'>Setup Bookmarklet</Link>
          </Action>
          <Action>
            <Link to='/delete-account'>Delete my account</Link>
          </Action>
        </ul>
      </SettingsContainer>
    </>
  }
}

Settings.propTypes = {
  user: PropTypes.object.isRequired,
  onInitialize: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired
}

export function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    onInitialize: bindActionCreators(loadUser, dispatch),
    saveUser: bindActionCreators(saveUser, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)