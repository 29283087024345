import { push } from 'connected-react-router'
import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/recipes'
import { normalize } from '../shared'
import { setError, clearError } from '../error'

export const loadRecipes = () => (dispatch) => {
  return api.loadRecipes().then(
    response => {
      dispatch({
        type: actionTypes.LOAD_RECIPES_SUCCESS,
        payload: normalize(response)
      })
    }
  )
}

export const loadRecipe = slug => (dispatch) => {
  return api.loadRecipe(slug).then(
    response => {
      dispatch({
        type: actionTypes.LOAD_RECIPES_SUCCESS,
        payload: normalize(response)
      })
    }
  )
}

export const createRecipe = body => (dispatch) => {
  clearError()(dispatch)

  return api.createRecipe(body).then(
    response => afterCreate(dispatch, response)
  ).catch(promise => {
    promise.then(data => {
      setError(data.message)(dispatch)
    })
  })
}

const afterCreate = (dispatch, response) => {
  dispatch({
    type: actionTypes.LOAD_RECIPES_SUCCESS,
    payload: normalize(response)
  })

  const url = '/recipes/' + response.slug;

  dispatch(
    push(url)
  )
}