import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Header from './header'
import Nav from './nav'
import MovedByNav from './movedByNav'
import NavigationHandler from './navigationHandler'
import Home from '../Home'
import Recipe from '../Recipe'
import SignUp from '../Auth/signUp'
import LogIn from '../Auth/logIn'
import SavedRecipes from '../SavedRecipes'
import ShoppingList from '../ShoppingList'
import Settings from '../Settings'
import Tag from '../Tag'
import Save from '../Save'

const MainContainer = styled.div`

`;

const ContentContainer = styled.div`
  padding: ${props => !!props.flash ? '136px' : '90px'} 18px 18px;

  @media (max-width: 900px) {
    padding: 70px 18px 18px;
  }
`;

const App = ({ history, flash }) => {
  return (
    <ConnectedRouter history={history}>
      <Route component={NavigationHandler} />
      <MainContainer>
        <Header />
        <MovedByNav>
          <ContentContainer flash={flash}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/recipes/:slug' component={Recipe} />
              <Route exact path='/sign-up' component={SignUp} />
              <Route exact path='/log-in' component={LogIn} />
              <Route exact path='/settings' component={Settings} />
              <Route exact path='/tag/:slug' component={Tag} />
              <Route exact path='/saved-recipes' component={SavedRecipes} />
              <Route exact path='/shopping-list' component={ShoppingList} />
              <Route exact path='/save' component={Save} />
            </Switch>
          </ContentContainer>
        </MovedByNav>
        <Nav />
      </MainContainer>
    </ConnectedRouter>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired
}

export default App;