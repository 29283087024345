import { push } from 'connected-react-router';
import * as actionTypes from '../../constants/actionTypes';
import * as helpers from '../../constants/helpers';
import * as api from '../../api/auth';
import * as localStorage from '../../library/localStorage';
import { setFlash } from '../flash';

export const signUp = body => (dispatch) => {
  return api.signUp(body).then(
    response => afterCreate(
      dispatch,
      actionTypes.CREATE_USER_SUCCESS,
      response
    )
  )
}

export const logIn = body => (dispatch) => {
  return api.logIn(body).then(
    response => afterCreate(
      dispatch,
      actionTypes.CREATE_SESSION_SUCCESS,
      response
    )
  )
}

export const logOut = () => (dispatch) => {

  dispatch({
    type: actionTypes.LOG_OUT
  })

  localStorage.setItem('user', helpers.INITIAL_USER_STATE);

  dispatch(
    push('/log-in')
  )

  setFlash('You are now logged out.')(dispatch)
}

const afterCreate = (dispatch, type, response) => {
  dispatch({
    type: type,
    response
  })

  localStorage.setItem('user', response);

  dispatch(
    push('/')
  )

  setFlash('You are now logged in.')(dispatch)
}