import * as constants from '../../constants/helpers';
import callApi from '../base';

export function signUp(body) {
  const url = constants.API_ROOT_URL + 'users'
  return callApi(url, 'POST', body)
}

export function logIn(body) {
  const url = constants.API_ROOT_URL + 'users/log-in'
  return callApi(url, 'POST', body)
}