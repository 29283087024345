import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const search = (state = initialState.search, action) => {
  switch(action.type) {
    case actionTypes.SEARCH_SUCCESS:
      return action.response
    case actionTypes.CLEAR_SEARCH:
      return initialState.search
    default:
      return state;
  }
}

export default search;