import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/list'

export const addToList = (slug) => (dispatch) => {
  return api.addToList(slug).then(
    () => (
      dispatch({
        type: actionTypes.ADD_TO_LIST_SUCCESS,
        payload: {
          slug
        }
      })
    )
  )
}

export const removeFromList = (slug) => (dispatch) => {
  return api.removeFromList(slug).then(
    () => (
      dispatch({
        type: actionTypes.REMOVE_FROM_LIST_SUCCESS,
        payload: {
          slug
        }
      })
    )
  )
}