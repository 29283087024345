import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { logOut } from '../../actions/auth';

const NavContainer = styled.div`
  background-color: #242424;
  position: fixed;
  top: 0; right: 0;
  width: 200px;
  height: 100vh;
  z-index: 1;
`;

const linkStyle = css`
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 25px 10px;
  text-decoration: none;
  color: #fff;
`;

const NavLink = styled(Link)`
  ${linkStyle}
`;

const NavAnchor = styled.a`
  ${linkStyle}
  cursor: pointer;
`;

class Nav extends Component {

  render() {
    return(
      <NavContainer>
        <ul>
          <li><NavLink to='/'>Home</NavLink></li>
          <li><NavLink to='/save'>+ Add Recipe</NavLink></li>
          {this.props.loggedIn ? (
            <div>
              <li><NavLink to='/saved-recipes'>Saved Recipes</NavLink></li>
              <li><NavLink to='/shopping-list'>Shopping List</NavLink></li>
              <li><NavLink to='/settings'>Settings</NavLink></li>
              <li><NavAnchor onClick={this.props.logOut}>Log Out</NavAnchor ></li>
            </div>
          ) : (
            <div>
              <li><NavLink to='/sign-up'>Sign Up</NavLink></li>
              <li><NavLink to='/log-in'>Log In</NavLink></li>
            </div>
          )}
        </ul>
      </NavContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: !!state.currentUser.jwt
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: bindActionCreators(logOut, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);