import React, { Component } from 'react'
import styled from 'styled-components'
import stringSimilarity from 'string-similarity'
import difference from 'lodash/difference'

const RecipeInstructions = styled.div`
  float: left;
  width: 65%;
  white-space: pre-wrap;
  font-size: 18px;

  @media (max-width: 900px) {
    width: 100%;
    float: none;
  }
`;

const SubHeader = styled.h3`
  font-family: 'Crimson Text', serif;
  font-size: 28px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
`;

const fontSize = '24px'

const ParsedInstructions = styled.div`
  font-family: 'Crimson Text', serif;
  font-size: ${fontSize};
  max-width: 600px;
  line-height: 1.4;

  .checkbox-hack {
    display: none;
  }

  .ingredient-tooltip {
    text-decoration: underline;
    cursor: pointer;
  }

  .checkbox-hack:checked + .ingredient-tooltip {
    color: green;
    visibility: hidden;
    font-size: 0;
  }

  .checkbox-hack:checked + .ingredient-tooltip:after {
    content: attr(data-ingredient);
    visibility: visible;
    font-size: ${fontSize};
  }
`;

class Instructions extends Component {

  render() {
    return(
      <RecipeInstructions>
        <SubHeader>Directions</SubHeader>
        <ParsedInstructions dangerouslySetInnerHTML={{__html: this.instructionsWithIngredientToolTips()}} />
      </RecipeInstructions>
    )
  }

  instructionsWithIngredientToolTips() {
    var instructions = this.props.recipe.instructions

    if (!instructions || this.props.ingredients < 1) { return '' }

    const indexLength = this.indexLength(this.props.ingredients)
    const index = this.stringIndex(instructions, indexLength)

    const reduced = this.props.ingredients.reduce((acc, ingredient) => {
      const matches = stringSimilarity.findBestMatch(ingredient.name, index)
      const target = matches.bestMatch.target
      const rating = matches.bestMatch.rating

      if (rating > 0.33) {
        // in case the string has like a paren or something.
        // Taken from here: https://stackoverflow.com/q/3446170/117554
        const escaped = target.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        return { ...acc, [escaped]: ingredient.input }
      } else {
        return acc
      }
    }, {})

    const regexString = Object.keys(reduced).join('|')
    const regex = new RegExp(regexString, 'ig')

    instructions = instructions.replace(regex, (match, $1) => {
      if (match) {
        return `<input type="checkbox" id="${$1}" class="checkbox-hack" /><label for="${$1}" class="ingredient-tooltip" data-ingredient="${reduced[match]}">${match}</label>`
      }
    })

    return instructions
  }

  indexLength(ingredients) {
    const map = ingredients.map(ingredient => (ingredient.name.match(/\s/g) || []).length)
    return Math.max(...map)
  }

  stringIndex(string, length) {
    return string.match(/\S+/g).flatMap((_, i, array) => {
      const cleaned = this.removePunctuation(array)
      const formatted = this.stripConjunctions(cleaned)
      return formatted.slice(i, i + length).map((_, j) => formatted.slice(i, i+j+1).join(' '))
    });
  }

  stripConjunctions(array) {
    const conjunctions = ['and', 'to', 'if', 'but', 'for', 'or', 'so', 'on', 'in']

    return difference(array, conjunctions)
  }

  removePunctuation(array) {
    return array.map(item => item.replace(/\.$|,$/, ''))
  }
}

export default Instructions;