import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';
import { handleRecipeToggle } from '../shared';

const recipes = (state = initialState.recipes, action) => {
  switch(action.type) {
    case actionTypes.LOAD_RECIPES_SUCCESS:
      return { ...state, ...action.payload }
    case actionTypes.SAVE_SUCCESS:
    case actionTypes.UNSAVE_SUCCESS:
      return handleRecipeToggle(state, action.payload, 'saved')
    case actionTypes.ADD_TO_LIST_SUCCESS:
    case actionTypes.REMOVE_FROM_LIST_SUCCESS:
      return handleRecipeToggle(state, action.payload, 'list')
    default:
      return state;
  }
}

export default recipes;