import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearFlash } from '../../actions/flash';

const FlashContainer = styled.div`
  position: relative;
`;

const StyledFlash = styled.div`
  background: #F5F5F5;
  text-align: center;
  padding: 15px;
`;

const CloseFlash = styled.a`
  position: absolute;
  right: 18px;
  top: 12px;
  font-size: 18px;
  cursor: pointer;
`;

class Flash extends Component {

  render() {
    if (!this.props.flash) { return false; }

    return(
      <FlashContainer>
        <StyledFlash>{this.props.flash}</StyledFlash>
        <CloseFlash onClick={this.props.clearFlash}>✕</CloseFlash>
      </FlashContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    flash: state.flash
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearFlash: bindActionCreators(clearFlash, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Flash);