import React from 'react';
import PropTypes from 'prop-types';

const Recipes = ({ recipes, Component, className }) => {
  return(
    <ul className={className}>
      {recipes.map(recipe =>
        <Component recipe={recipe} key={recipe.id} />
      )}
    </ul>
  )
}

Recipes.propTypes = {
  recipes: PropTypes.array.isRequired
};

export default Recipes;