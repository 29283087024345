export const normalize = (response) => {
  if (!response) { return {} }

  const array = Array.isArray(response) ? response : [response]
  const recipes = reduce(array, 'slug')

  return recipes
}

const reduce = (array, key) => {
  return array.reduce((map, object) => {
    map[object[key]] = object;
    return map;
  }, {})
}