import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/save'
import { normalize } from '../shared'

export const loadSaves = () => (dispatch) => {
  return api.loadSaves().then(
    response => (
      dispatch({
        type: actionTypes.LOAD_RECIPES_SUCCESS,
        payload: normalize(response)
      })
    )
  )
}

export const save = (slug) => (dispatch) => {
  return api.save(slug).then(
    () => (
      dispatch({
        type: actionTypes.SAVE_SUCCESS,
        payload: {
          slug
        }
      })
    )
  )
}

export const unsave = (slug) => (dispatch) => {
  return api.unsave(slug).then(
    () => (
      dispatch({
        type: actionTypes.UNSAVE_SUCCESS,
        payload: {
          slug
        }
      })
    )
  )
}