import * as constants from '../../constants/helpers'
import callApi from '../base'

export function loadSaves() {
  const url = constants.API_ROOT_URL + 'saves'
  return callApi(url)
}

export function save(slug) {
  const url = constants.API_ROOT_URL + 'recipes/' + slug + '/save'
  return callApi(url, 'POST')
}

export function unsave(slug) {
  const url = constants.API_ROOT_URL + 'recipes/' + slug + '/save'
  return callApi(url, 'DELETE')
}