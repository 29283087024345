import * as actionTypes from '../../constants/actionTypes';
import * as api from '../../api/tag';
import { normalize } from '../shared';

export const loadTag = (slug) => (dispatch) => {
  return api.loadTag(slug).then(
    response => {
      dispatch({
        type: actionTypes.LOAD_RECIPES_SUCCESS,
        payload: normalize(response)
      })
    }
  )
}