import * as actionTypes from '../../constants/actionTypes';
import * as api from '../../api/search';

export const search = query => (dispatch) => {
  return api.search(query).then(
    response => dispatch({
      type: actionTypes.SEARCH_SUCCESS,
      response
    })
  )
}

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_SEARCH
  })
}