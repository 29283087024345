import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import SaveToggle from '../Shared/saveToggle'
import ShoppingListToggle from '../Shared/shoppingListToggle'

const Item = styled.li`
  padding: 25px 0;
  overflow: hidden;
  border-bottom: 1px solid #d6d6d6;
`

const Info = styled.div`
  float: left;
`

const Actions = styled.div`
  float: right;
`

const StyledShoppingListToggle = styled(ShoppingListToggle)`
  margin-right: 15px;
`

const SavedItem = ({ recipe }) => {
  return(
    <Item>
      <Info>
        <Link to={'/recipes/' + recipe.slug}>{recipe.title}</Link>
      </Info>
      <Actions>
        <StyledShoppingListToggle
          {...recipe}
          removeText='remove from list'
          addText='add to list'
        />
        <SaveToggle
          {...recipe}
          removeText='unsave'
          addText='save'
        />
      </Actions>
    </Item>
  )
}

export default SavedItem