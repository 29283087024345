import * as actionTypes from '../../constants/actionTypes'
import * as api from '../../api/pantry'

export const addToPantry = (body) => (dispatch) => {
  return api.addToPantry(body).then(
    () => (
      dispatch({
        type: actionTypes.ADD_TO_PANTRY,
        payload: body.name
      })
    )
  )
}

export const removeFromPantry = (body) => (dispatch) => {
  return api.removeFromPantry(body).then(
    () => (
      dispatch({
        type: actionTypes.REMOVE_FROM_PANTRY,
        payload: body.name
      })
    )
  )
}