import * as constants from '../../constants/helpers';
import callApi from '../base';

export function loadItems() {
  const url = constants.API_ROOT_URL + 'items'
  return callApi(url)
}

export function saveItem(body) {
  const url = constants.API_ROOT_URL + 'items'
  return callApi(url, 'POST', body)
}

export function updateItem(id, body) {
  const url = constants.API_ROOT_URL + 'items/' + id
  return callApi(url, 'PUT', body)
}

export function deleteItem(id) {
  const url = constants.API_ROOT_URL + 'items/' + id
  return callApi(url, 'DELETE')
}