import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { clearSearch } from '../../actions/search';

const SearchResultsContainer = styled.div`
  border-top: 1px solid #ccc;
  padding: 25px 18px;
  position: relative;
`;

const Close = styled.a`
  position: absolute;
  top: 25px; right: 18px;
  font-size: 34px;
  cursor: pointer;
  line-height: 0;
`;

const SearchResultGridContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2.666%;
`;

const SearchResultColumn = styled.ul`

`;

const ColumnTitle = styled.h3`
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
`;

const SearchResult = styled.li`
  margin-bottom: 5px;
`;

class Search extends Component {

  constructor() {
    super()
    this.clearSearch = this.clearSearch.bind(this);
  }

  render() {
    if (this.props.recipes.length < 1 && this.props.tags.length < 1) { return null; }

    return(
      <SearchResultsContainer>
        <Close onClick={this.clearSearch}>×</Close>
        <SearchResultGridContainer>
          {this.props.recipes.length > 0 &&
            <SearchResultColumn>
              <ColumnTitle>Recipes</ColumnTitle>
              {this.props.recipes.map(recipe =>
                <SearchResult key={recipe.slug}>
                  <Link to={'/recipes/' + recipe.slug}>{recipe.text}</Link>
                </SearchResult>
              )}
            </SearchResultColumn>
          }
          {this.props.tags.length > 0 &&
            <SearchResultColumn>
              <ColumnTitle>Tags</ColumnTitle>
              {this.props.tags.map(tag =>
                <SearchResult key={tag.slug}>
                  <Link to={'/tag/' + tag.slug}>{tag.text}</Link>
                </SearchResult>
              )}
            </SearchResultColumn>
          }
        </SearchResultGridContainer>
      </SearchResultsContainer>
    )
  }

  clearSearch(event) {
    event.preventDefault();
    this.props.clearSearch();
  }
}

Search.propTypes = {
  recipes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  clearSearch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    recipes: filter(state.search, 'Recipe'),
    tags: filter(state.search, 'Tag')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSearch: bindActionCreators(clearSearch, dispatch)
  }
}

const filter = (state, filter) => {
  return state.filter((object) => object.type === filter)
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);