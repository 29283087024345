import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeNav } from '../../actions/nav'
import { clearSearch } from '../../actions/search'

class NavigationHandler extends Component {
  constructor() {
    super()
    this.run = this.run.bind(this);
  }

  componentDidMount() {
    this.run()
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props

    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search

    if (isDifferentPathname || isDifferentSearch) {
      this.run()
    }
  }

  run() {
    this.props.closeNav()
    this.props.clearSearch()
  }

  render() { return null }
}

NavigationHandler.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  closeNav: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeNav, clearSearch }, dispatch)
}

export default connect(null, mapDispatchToProps)(NavigationHandler)