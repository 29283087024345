export const LOAD_RECIPES_SUCCESS = 'LOAD_RECIPES_SUCCESS'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const CLOSE_NAV = 'CLOSE_NAV'
export const LOG_OUT = 'LOG_OUT'
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const UNSAVE_SUCCESS = 'UNSAVE_SUCCESS'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const DISPLAY_FLASH = 'DISPLAY_FLASH'
export const CLEAR_FLASH = 'CLEAR_FLASH'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const ADD_TO_LIST_SUCCESS = 'ADD_TO_LIST_SUCCESS'
export const REMOVE_FROM_LIST_SUCCESS = 'REMOVE_FROM_LIST_SUCCESS'
export const LOAD_ITEMS_SUCCESS = 'LOAD_ITEMS_SUCCESS'
export const SAVE_ITEM = 'SAVE_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const LOAD_REOCCURING = 'LOAD_REOCCURING'
export const LOAD_PANTRY = 'LOAD_PANTRY'
export const ADD_TO_PANTRY = 'ADD_TO_PANTRY'
export const REMOVE_FROM_PANTRY = 'REMOVE_FROM_PANTRY'
export const ADD_TO_REOCCURING = 'ADD_TO_REOCCURING'
export const REMOVE_FROM_REOCCURING = 'REMOVE_FROM_REOCCURING'