import * as actionTypes from '../../constants/actionTypes';

export const toggleNav = (event) => (dispatch, getState) => {
  event.preventDefault()
  dispatch({
    type: actionTypes.TOGGLE_NAV
  })
}

export const closeNav = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLOSE_NAV
  })
}