import * as actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

const nav = (state = initialState.nav, action) => {
  switch(action.type) {
    case actionTypes.TOGGLE_NAV:
      return !state
    case actionTypes.CLOSE_NAV:
      return false
    default:
      return state
  }
}

export default nav;