import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signUp } from '../../actions/auth'
import {
  Container,
  StyledForm,
  Header,
  Input,
  Button,
  Alternates
} from './style'

class SignUp extends Component {
  render() {
    return(
      <Container>
        <StyledForm action={this.props.signUp}>
          <Header>Sign Up</Header>
          <Input type='email' placeholder='Email Address' name='email' autoComplete='username' />
          <Input type='password' placeholder='Password' name='password' autoComplete='new-password' />
          <Input type='password' placeholder='Password Confirmation' name='password_confirmation' autoComplete='new-password' />
          <Button type='submit' value='Complete Sign Up' />
          <Alternates>
            Already have an account? <Link to='/log-in'>Log In</Link>.
          </Alternates>
        </StyledForm>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signUp: bindActionCreators(signUp, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(SignUp)