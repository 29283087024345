import * as actionTypes from '../../constants/actionTypes';
import * as helpers from '../../constants/helpers';
import initialState from '../initialState';

const auth = (state = initialState.currentUser, action) => {
  switch(action.type) {
    case actionTypes.CREATE_USER_SUCCESS:
      return action.response
    case actionTypes.CREATE_SESSION_SUCCESS:
      return action.response
    case actionTypes.LOG_OUT:
      return helpers.INITIAL_USER_STATE
    default:
      return state
  }
}

export default auth;